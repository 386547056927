<template>
    <div class="content">
        <!--        公共导航-->
        <commonTitle></commonTitle>

        <el-card class="card"
                 v-loading="loading"
                 element-loading-text="正在投票中，请稍后"
        >
            <el-link type="primary" :underline="false" @click="back">返回</el-link>
            <!--维修项目名称 -->
            <h2>{{vote.voteName}}</h2>
            <p class="second_title"><span>发布单位：{{vote.orgName}}</span><span style="margin-left: 15px;">发布时间：{{vote.createDate}}</span>
            </p>
            <div class="card_content">
                <p>{{user.nickname}}您好！</p>
                <!--                <p>-->
                <!--                    阳光小区1号楼1单元关于公共区域电梯的维修资金表决1阳光小区1号楼1单元关于公共区域电梯的维修资金表决1阳光小区1号楼1单-->
                <!--                    元关于公共区域电梯的维修资金表决1阳光小区1号楼1单元关于公共区域电梯的维修资金表决1阳光小区1号楼1单元-->
                <!--                </p>-->
                <p>
                    分摊明细如下：
                <table>
                    <tr>
                        <td>
                            物业单位
                        </td>
                        <td>{{vote.orgName}}</td>
                    </tr>
                    <tr>
                        <td>
                            项目名称
                        </td>
                        <td>
                            {{vote.name}}
                        </td>
                    </tr>
                    <tr>
                        <td>维修类型</td>
                        <td>{{vote.type}}</td>
                    </tr>
                    <tr>
                        <td>维修对象</td>
                        <td>
                            {{vote.object}}
                        </td>
                    </tr>
                    <tr>
                        <td>维修规模</td>
                        <td>
                            {{vote.scale}}
                        </td>
                    </tr>
                    <tr>
                        <td>总分摊金额</td>
                        <td>
                            {{vote.moneyCount}}
                        </td>
                    </tr>
                    <tr>
                        <td>分摊金额</td>
                        <td>
                            {{vote.money}}
                        </td>
                    </tr>
                </table>
                <span> 维修项目描述：</span>
                {{vote.descr}}
                </p>

            </div>
            <p class="shureMessage">您是否同意该维修事项？</p>
            <div class="btn_shure">
                <el-button size="small" type="success" @click="tVote(0)">同意</el-button>
                <el-button size="small" type="warning" @click="tVote(1)">不同意</el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
    import commonTitle from "../../commonTitle";

    export default {
        name: "touPiaoList",
        components: {
            commonTitle
        }, data() {
            return {
                vote: {},//投票页面传来的数据
                url: this.$Config.base_server,
                loading: false,
                user: {}, //当前用户
            }
        }, mounted() {
            this.vote = JSON.parse(localStorage.getItem("vote"));
            this.user = JSON.parse(localStorage.getItem("user"));
        }, methods: {
            back() {
                //返回
                this.$router.go(-1);
            }, tVote(value) {

            let tip="是否对"+this.vote.voteName+"经行投票?";

      this.$confirm(tip, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const _this = this;
                _this.loading = true;
                $.ajax({
                    url: _this.url + "/api-public/repairShareDetail/updateVoteResult?access_token=" + localStorage.getItem("token"),
                    data: {
                        voteResult: value,
                        detailId: _this.vote.detailId,
                        voteId: JSON.parse(localStorage.getItem("vote")).id
                    },
                    contentType: 'application/json',
                    success: function (res) {
                        _this.loading = false;
                        if (res.resp_code == 0) {
                            _this.$message({
                                message: res.resp_msg,
                                type: 'success',
                                duration: 1000,
                                onClose: function () {
                                    _this.$router.go(-1); //跳回首页
                                }
                            })
                        }
                        if (res.resp_code == 1) {
                            _this.$message({
                                message: res.resp_msg,
                                type: 'error',
                                duration: 1000
                            })
                        }
                    },error:function() {
                      _this.loading = false;  
                    }
                });
        }).catch(() => {
             this.loading = false;     
        });

                
            }
        }
    }
</script>

<style scoped lang="scss">
    .content {
        width: 1200px;
        padding: 27px 111px 109px;;
        margin: 0 auto;
        background: #fff;
        min-height: 400px;

        .card {
            padding: 0 56px 20px;
            margin-top: 55px;

            .btn_shure {
                display: flex;
                justify-content: center;

                .el-button {
                    width: 128px;
                }
            }

            .shureMessage {
                text-align: center;
                font-size: 14px;
                color: #000000;
                margin: 32px 0 24px;
            }

            h2 {
                color: #606266;
                font-size: 14px;
                text-align: center;
                margin: 20px 0 15px;
            }

            .second_title {
                color: #8E9198;
                font-size: 12px;
                text-align: center;
            }

            .card_content {
                border-top: 1px solid #E0E3E8;
                margin-top: 20px;
                padding-top: 32px;
                padding-bottom: 53px;
                border-bottom: 12px solid #F7F6F9;

                p {
                    color: #666666;
                    font-size: 14px;
                    line-height: 27px;

                    &:last-child {
                        text-indent: 2em;
                    }

                    span {
                        margin-left: 16px;
                        display: inline-block;
                        margin-top: 10px;
                    }
                }
            }

            .card_content table {
                line-height: 40px;
                width: 550px;
                border-collapse: collapse;
                border: none;
                margin: 10px auto;

                td {
                    border: 1px solid #8E9198;

                    &:last-child {
                        width: 400px;
                        text-align: center;
                    }
                }
            }
        }

    }
</style>